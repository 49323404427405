import { afterNextRender, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DestinyInventoryItemDefinition, HttpClientConfig } from 'bungie-api-ts/destiny2';
import { firstValueFrom, merge, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { Pursuit } from '../checklist/checklist.types';
import { LoadingComponent } from '../types';
import { catchError, filter, first, map, mergeMap, tap, timeout } from 'rxjs/operators';
import { DestinyRecipesItem, DestinyRecipesPerk } from '../types/destiny-recipes-item';
import { ErrorService } from './error.service';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { logError } from '../console';
import { WeaponWishes } from '../checklist/weapons/targeted-weapons';
import { VaultCleanerConfig, VaultCleanerConfigOption } from '../vault-cleaner/vault-cleaner.types';
import { environment } from '../../environments/environment';
import { cloneDeep } from 'lodash';
import { isPlatformServer } from '@angular/common';

export type TooltipInstanceType = 'bounty' | 'item' | 'mod';

export interface ScoreDisplayConfig {
  type: 'percentage' | 'rating';
  showPoly: boolean;
}

export interface TooltipInstance {
  type: TooltipInstanceType;
  instance?: DestinyRecipesItem;
  bounty?: Pursuit;
  richPerks?: boolean;
  target?: WeaponWishes[];
  rules?: {[key: string]: VaultCleanerConfigOption};
  showPerksInitialState?: boolean;
}

export enum ManifestLoadingState {
  LOADING,
  DOWNLOADING,
  LOADED,
}

export interface PerkPopupConfig {
  perk: DestinyRecipesPerk;
  highlighted: boolean;
}

export interface DestinyRecipesStateOptions {
  'vault.config': VaultCleanerConfig;
  'checklist.countTrashAsFreeSpace': boolean;
  'checklist.xpBoosts': { pass: boolean; shell: boolean; rest: boolean; };
  'checklist.toggledSections': { weapons: boolean };
  'checklist.announceRead': boolean;
  'loot.showAllPerks': boolean;
  'loot.showOnlyNew': boolean;
  'loot.rowsPerPage': number;
  'loot.richComparison': boolean;
  'loot.lastTreated': {
    lastArmor: string;
    lastWeapon: string;
  };
  'global.lang': string;
  'global.membership': string;
  'global.scoreDisplay': ScoreDisplayConfig;
  'global.tooltipSide': 'left' | 'right';
}

export enum LoaderState {
  NONE = 'NONE',
  INIT = 'INIT',
  DOWNLOADING_MANIFEST = 'DOWNLOADING_MANIFEST',
  LOADING_MANIFEST = 'LOADING_MANIFEST',
  AUTHENTICATING = 'AUTHENTICATING',
  LOADING_PROFILE = 'LOADING_PROFILE',
  LOADING_ROLLS = 'LOADING_ROLLS',
  LOADING_COMMON_SETTINGS = 'LOADING_COMMON_SETTINGS',
}

export interface LoaderOptions {
  background?: boolean;
  force?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  tooltipOpened = false;
  tooltipType: 'target' | 'normal';
  tooltipContent: DestinyInventoryItemDefinition;
  tooltipContentInstance: TooltipInstance;
  tooltipPosition: {x: number, y: number} = {x: 0, y: 0};

  tooltipLocked: boolean;

  loaderSub = new Subject<{components: LoadingComponent[], options?: { force?: boolean, background?: boolean }}>();
  manifestLoadSub: Subject<ManifestLoadingState> = new Subject<ManifestLoadingState>();

  loadingComponentState = new Subject<{component: LoadingComponent, loaded: boolean}>();
  components = {
    [LoadingComponent.MANIFEST]: false,
    [LoadingComponent.INVENTORY]: false,
    [LoadingComponent.AUTH]: false,
    [LoadingComponent.VOLTRON]: false,
    [LoadingComponent.COMMON]: false
  };

  usingImportedData = false;

  perkPopupConfig: PerkPopupConfig;
  perkPopupPosition: {x: number, y: number} = {x: 0, y: 0};

  devMode = !environment.production;
  localStorageAvailable: boolean;

  loadingState: LoaderState = LoaderState.INIT;
  isServer = false;
  loadingListener: Subscription;

  version: string;

  private stateOptions: DestinyRecipesStateOptions;

  constructor(
    private errorService: ErrorService,
    public http: HttpClient,
    public ga: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) platformId: any,
    ) {
    this.isServer = isPlatformServer(platformId);
    if (!this.isServer) {
      this.version = localStorage.getItem('set-version');
    }
  }

  public httpClient = <Return>(config: HttpClientConfig) => {
    return firstValueFrom<Return>(this.http.request<Return>(config.method, config.url, {
      responseType: 'json',
      body: config.body,
      params: config.params,
    }).pipe(timeout(120000)));
  }

  setVersion(version: string) {
    this.version = version;
    if (!this.isServer) {
      localStorage.setItem('set-version', version);
    }
  }

  clearVersion() {
    this.version = null;
    if (!this.isServer) {
      localStorage.removeItem('set-version');
    }
  }

  startWatchingErrors(state?: string) {
    this.errorService.setCritical(true, state);
  }
  stopWatchingErrors() {
    this.errorService.setCritical(false);
  }

  togglePerkPopup(config: PerkPopupConfig, position: {x: number, y: number}) {
    this.perkPopupConfig = config;
    this.perkPopupPosition = position;
  }

  lockTooltip() {
    this.tooltipLocked = true;
  }
  unlockTooltip() {
    this.tooltipLocked = false;
  }

  openTooltip(item: DestinyInventoryItemDefinition, tooltipInstance: TooltipInstance, tooltipType: 'target' | 'normal') {
    this.tooltipContent = item;
    this.tooltipType = tooltipType;
    this.tooltipContentInstance = tooltipInstance;
    this.tooltipOpened = true;
  }

  closeTooltip() {
    this.tooltipContent = null;
    this.tooltipContentInstance = null;
    this.tooltipOpened = false;
    this.tooltipLocked = false;
  }

  loadComponents(components: LoadingComponent[], options?: LoaderOptions) {
    if (!this.loadingListener) {
      this.loadingListener = this.loadingComponentState.subscribe((state) => {
        this.components[state.component] = state.loaded;
      });
    }
    this.loaderSub.next({components, options});
  }

  waitFor(component: LoadingComponent): Observable<{component: LoadingComponent, loaded: boolean}> {
    if (this.components[component]) {
      return of({component, loaded: true});
    }
    return this.loadingComponentState.pipe(
      filter((state) => state.component === component),
      first(),
    );
  }

  waitAny(components: LoadingComponent[]): Observable<any> {
    return merge(...components.map((c) => this.waitFor(c))).pipe(filter((loaded) => loaded.loaded));
  }

  waitAll(components: LoadingComponent[]): Observable<void> {
    return new Observable<void>((obs) => {
      const finished = components.map((c) => ({component: c, loaded: false}));
      components.forEach((component) => this.waitFor(component).subscribe((loaded) => {
        finished.find((c) => c.component === component).loaded = true;
        if (finished.every((c) => c.loaded)) {
          obs.next();
          obs.complete();
        }
      }));
    });
  }

  registerAnalyticsEvent(name: string, category: string, user: string | 'anonymous') {
    try {
      this.ga.event(name, category, user);
    } catch (err) {
      logError(err);
    }
  }

  loadScoreDisplayConfig(): ScoreDisplayConfig {
    if (this.hasOption('global.scoreDisplay')) {
      return this.getOption('global.scoreDisplay');
    } else {
      return { type: 'percentage', showPoly: false };
    }
  }

  loadOptions() {
    try {
      this.stateOptions = JSON.parse(this.getLocalStorageItem('destiny-recipes-options'));
      if (!this.stateOptions) {
        this.stateOptions = {} as DestinyRecipesStateOptions;
      }
    } catch (err) {
      this.stateOptions = {} as DestinyRecipesStateOptions;
      this.removeLocalStorageItem('destiny-recipes-option');
    }
  }

  setOption<K extends keyof DestinyRecipesStateOptions>(key: K, value: DestinyRecipesStateOptions[K]): void {
    if (!this.stateOptions) {
      this.loadOptions();
    }
    this.stateOptions[key] = value;
    this.setLocalStorageItem('destiny-recipes-options', JSON.stringify(this.stateOptions));
  }

  hasOption<K extends keyof DestinyRecipesStateOptions>(key: K): boolean {
    if (!this.stateOptions) {
      this.loadOptions();
    }
    return this.stateOptions.hasOwnProperty(key) && this.stateOptions[key] !== undefined;
  }

  getOption<K extends keyof DestinyRecipesStateOptions>(key: K, defaultValue?: any): DestinyRecipesStateOptions[K] {
    if (!this.stateOptions) {
      this.loadOptions();
    }
    return this.hasOption(key) ? this.stateOptions[key] : defaultValue;
  }

  deleteOption(key: keyof DestinyRecipesStateOptions) {
    if (!this.stateOptions) {
      this.loadOptions();
    }
    delete this.stateOptions[key];
    this.setLocalStorageItem('destiny-recipes-options', JSON.stringify(this.stateOptions));
  }

  enableDevMode() {
    console.log('[Dev mode enabled]');
    this.devMode = true;
  }

  setLocalStorageItem(key: string, value: string) {
    if (!this.localStorageAvailable) { return; }
    window.localStorage.setItem(key, value);
  }

  getLocalStorageItem(key: string) {
    if (!this.localStorageAvailable) { return undefined; }
    return window.localStorage.getItem(key);
  }

  removeLocalStorageItem(key: string) {
    if (!this.localStorageAvailable) { return; }
    window.localStorage.removeItem(key);
  }

  clearLocalStorage() {
    if (!this.localStorageAvailable) { return; }
    window.localStorage.clear();
  }
}
