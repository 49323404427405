import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@Services/auth.service';
import { BungieApiResponse, CleanupConfig, LoadingComponent } from '../../types';
import { CoreService, ScoreDisplayConfig } from '@Services/core.service';
import { DataService } from '@Services/data.service';
import {
  VaultCleanerConfig,
  VaultCleanerConfigImport,
  VaultCleanerConfigOption,
  VaultCleanerFactoryId
} from '../vault-cleaner.types';
import {
  DestinyItemCategoryDefinition,
  DestinyItemSubType,
  DestinyPresentationNodeDefinition,
  DestinyProfileResponse,
  ItemLocation,
  TierType
} from 'bungie-api-ts/destiny2';
import * as _ from 'lodash';
import { DndDropEvent } from 'ngx-drag-drop';
import { RulesService } from '@Services/rules.service';
import { VoltronService } from '@Services/voltron.service';
import { DestinyItemCategory } from '@Services/destinyItemCategory';
import { DestinyArmorAppraisalRating, DestinyRecipesItem } from '@Types/destiny-recipes-item';
import { from, Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, delay, mergeMap, tap, toArray } from 'rxjs/operators';
import { ItemHelper } from '@Common/item.helper';
import { serializeError } from 'serialize-error';
import { CookieService } from 'ngx-cookie-service';
import { TextModalComponent } from '@Common/text-modal/text-modal.component';
import { ModalService } from '@Services/modal.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ShareModalComponent } from '../share-modal/share-modal.component';
import { ImportModalComponent } from '../import-modal/import-modal.component';
import { ImportedDataService } from '@Services/imported-data.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { ImportCleanupModalComponent } from '../import-cleanup-modal/import-cleanup-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoCard } from 'bungie-api-ts/user';
import { TranslateService } from '@ngx-translate/core';
import { TagUpdate } from '@destinyitemmanager/dim-api-types';
import { DimSyncService } from '@Services/dim-sync.service';
import { ConfirmTagModalComponent } from '../confirm-tag-modal/confirm-tag-modal.component';
import { logError } from '../../console';
import { defaultConfig } from '../vault-cleaner-configs';
import { ArmorGuideComponentDialog } from '../armor-guide/armor-guide-component-dialog.component';
import { convertArrayToObject } from '@Utils/helpers';
import { cloneDeep } from 'lodash';
import { AdComponent } from '@Common/ad/ad.component';

export interface CleaningStep {
  type: DestinyItemCategory[];
  category: 'weapon' | 'armor';
  items?: {[itemHash: string]: { keep?: DestinyRecipesItem[], remove?: DestinyRecipesItem[] } };
  active: boolean;
  notType?: DestinyItemCategory[];
}

export class MaxTriesError extends Error {
  constructor(public itemsInError: {keep: DestinyRecipesItem[], remove: DestinyRecipesItem[]}, public errorStacks: any) {
    super('Max tries reached');
  }
}

const ShapeCustomOverride = {
  4: DestinyArmorAppraisalRating.A,
  3: DestinyArmorAppraisalRating.B,
  2: DestinyArmorAppraisalRating.C,
  1: DestinyArmorAppraisalRating.D,
  0: DestinyArmorAppraisalRating.E,
};

@Component({
  selector: 'app-vault-cleaner',
  templateUrl: './vault-cleaner.component.html',
  styleUrls: ['./vault-buttons.scss', 'vault-config.scss', 'vault-step.scss', './vault-cleaner.component.scss']
})
export class VaultCleanerComponent implements OnInit {
  get currentStep(): CleaningStep {
    return this.steps[this.currentStepIndex];
  }

  get useImportedData(): boolean {
    return this.coreService.usingImportedData;
  }

  set useImportedData(bool: boolean) {
    this.coreService.usingImportedData = bool;
  }

  get data(): DataService {
    if (this.useImportedData) {
      return this.importedDataService;
    }
    return this.dataService;
  }
  get dimSyncAvailable(): boolean {
    return !!this.dimSyncService.profile;
  }

  constructor(
    private authService: AuthService,
    private coreService: CoreService,
    private dataService: DataService,
    private ruleService: RulesService,
    public voltronService: VoltronService,
    private cookieService: CookieService,
    private modalService: ModalService,
    private importedDataService: ImportedDataService,
    private clipboard: ClipboardService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dimSyncService: DimSyncService
  ) { }
  authenticated: boolean;
  reloading: boolean;
  inventoryReady: boolean;
  manifestReady: boolean;
  voltronReady: boolean;
  loaded: boolean;
  error: string;

  loadingVault: boolean;
  processing: boolean;
  state: 'config' | 'sorting' | 'executing' | 'locking' | 'done' = 'config';

  weapons: number;
  armors: number;
  totalItems: number;

  showWeaponStep = false;
  polyvalentScoresOnly;
  scoreDisplayType: ScoreDisplayConfig;
  armorTuningCheck: 'global' | 'fine' = 'global';

  defaultSteps: CleaningStep[] = [
    { category: 'weapon', type: [DestinyItemCategory.W_AUTO_RIFLE], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_PULSE], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_REVOLVER], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_SUBMACHINEGUN], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_SCOUT], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_SIDEARM], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_BOW], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_SHOTGUN], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_SNIPER], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_FUSION], notType: [DestinyItemCategory.W_LINEAR_FUSION], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_SWORDS], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_GLAIVE], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_GRENADE_LAUNCHER], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_LMG], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_ROCKET], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_LINEAR_FUSION], active: true },
    { category: 'weapon', type: [DestinyItemCategory.W_TRACE_RIFLE], active: true },
    //
    { category: 'armor', type: [DestinyItemCategory.HUNTER, DestinyItemCategory.ARMOR ], active: true },
    { category: 'armor', type: [DestinyItemCategory.WARLOCK, DestinyItemCategory.ARMOR], active: true },
    { category: 'armor', type: [DestinyItemCategory.TITAN, DestinyItemCategory.ARMOR], active: true },
  ];
  steps: Array<CleaningStep>;
  backgroundSorting: boolean;
  currentStepIndex: number;
  catagoryNames: {[categoryHash: number]: string};
  currentDropZone: string;
  fromState: 'keep' | 'remove';

  minimumValueFormControl = new UntypedFormControl(65, [
    Validators.min(50), Validators.max(70), Validators.pattern(/^[0-9]{2}$/)
  ]);

  minimumKeepPvPRollFormControl = new UntypedFormControl(101, [
    Validators.min(0), Validators.max(100), Validators.pattern(/^[0-9].*$/)
  ]);

  minimumKeepPvERollFormControl = new UntypedFormControl(101, [
    Validators.min(0), Validators.max(100), Validators.pattern(/^[0-9].*$/)
  ]);

  minimumKeepPolyRollFormControl = new UntypedFormControl(101, [
    Validators.min(0), Validators.max(100), Validators.pattern(/^[0-9].*$/)
  ]);

  minimumGlobalQualityFormControl = new UntypedFormControl('A');

  minimumTopScoreFormControl = new UntypedFormControl(31, [
    Validators.min(22), Validators.max(34), Validators.pattern(/^[0-9].*$/)
  ]);

  minimumBottomScoreFormControl = new UntypedFormControl(31, [
    Validators.min(22), Validators.max(34), Validators.pattern(/^[0-9].*$/)
  ]);

  minimumMobResFormControl = new UntypedFormControl(-4, [
    Validators.min(-12), Validators.max(0), Validators.pattern(/^[0-9].*$/)
  ]);

  minimumRecResFormControl = new UntypedFormControl(-3, [
    Validators.min(-12), Validators.max(0), Validators.pattern(/^[0-9].*$/)
  ]);

  minimumDisFormControl = new UntypedFormControl(20, [
    Validators.min(2), Validators.max(30), Validators.pattern(/^[0-9].*$/)
  ]);

  // Super rules are rules where if they are true, they must be kept whatever the false rules.
  superRules: VaultCleanerFactoryId[] = [
    VaultCleanerFactoryId.WEAPONS_LOCKED,
    VaultCleanerFactoryId.ARMOR_LOCKED,
    VaultCleanerFactoryId.ARMOR_DIM_KEEP_INFUSE,
    VaultCleanerFactoryId.ARMOR_DIM_KEEP_FAVORITE,
    VaultCleanerFactoryId.ARMOR_DIM_KEEP_KEEP,
    VaultCleanerFactoryId.WEAPONS_DIM_KEEP_INFUSE,
    VaultCleanerFactoryId.WEAPONS_DIM_KEEP_FAVORITE,
    VaultCleanerFactoryId.WEAPONS_DIM_KEEP_KEEP,
    VaultCleanerFactoryId.WEAPONS_DIM_KEEP_LOADOUT,
    VaultCleanerFactoryId.ARMOR_DIM_KEEP_LOADOUT,
    VaultCleanerFactoryId.WEAPONS_DIM_KEEP_ARCHIVE,
    VaultCleanerFactoryId.WEAPONS_DIM_KEEP_ARCHIVE
  ];
  config: VaultCleanerConfig = {
    weapons: {
      execute: true,
      groupArchetypes: false,
      groupElements: false,
      options: [
        { id: VaultCleanerFactoryId.WEAPONS_BASE, execute: true, hidden: true },
        //  { id: VaultCleanerFactoryId.WEAPONS_ALL, execute: false, onChange: this.toggleWeaponFilters.bind(this) },
        { id: VaultCleanerFactoryId.WEAPONS_GODROLL_PVE, execute: true, onChange: this.toggleWeaponFilters.bind(this),
          customization: {
            formControl: this.minimumKeepPvERollFormControl,
            type: 'number',
            options: {
              sliderMax: 100,
              sliderMin: 0
            }
          },
        },
        { id: VaultCleanerFactoryId.WEAPONS_GODROLL_PVP, execute: true, onChange: this.toggleWeaponFilters.bind(this),
          customization: {
            formControl: this.minimumKeepPvPRollFormControl,
            type: 'number',
            options: {
              sliderMax: 100,
              sliderMin: 0
            }
          },
        },
        { id: VaultCleanerFactoryId.WEAPONS_PREFER_POLY, execute: false, onChange: this.toggleWeaponFilters.bind(this),
          customization: {
            formControl: this.minimumKeepPolyRollFormControl,
            type: 'number',
            options: {
              sliderMax: 100,
              sliderMin: 0
            }
          },
        },
        { id: undefined, execute: false },
        { id: VaultCleanerFactoryId.WEAPONS_BEST_POWER_LEVEL, execute: false, onChange: this.toggleWeaponFilters.bind(this) },
        { id: VaultCleanerFactoryId.WEAPONS_CURATED, execute: false, onChange: this.toggleWeaponFilters.bind(this) },
        { id: VaultCleanerFactoryId.WEAPONS_EXOTICS, execute: false, onChange: this.toggleWeaponFilters.bind(this) },
        { id: VaultCleanerFactoryId.WEAPONS_MASTERWORK, execute: false, onChange: this.toggleWeaponFilters.bind(this) },
        { id: VaultCleanerFactoryId.WEAPONS_DEEPSIGHT, execute: true, onChange: this.toggleWeaponFilters.bind(this) },
        { id: VaultCleanerFactoryId.WEAPONS_CRAFTED, execute: true, onChange: this.toggleWeaponFilters.bind(this) },
        { id: VaultCleanerFactoryId.WEAPONS_HAS_CRAFTED, execute: true, onChange: this.toggleWeaponFilters.bind(this) },
        { id: VaultCleanerFactoryId.WEAPONS_CAN_CRAFT, execute: true, onChange: this.toggleWeaponFilters.bind(this) },
        { id: undefined, execute: false },
        { id: VaultCleanerFactoryId.WEAPONS_LOCKED, execute: false },
        { id: undefined, execute: false},
        { id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_LOADOUT, execute: false, onChange: this.toggleWeaponFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_FAVORITE, execute: false, onChange: this.toggleWeaponFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_KEEP, execute: false, onChange: this.toggleWeaponFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_INFUSE, execute: false, onChange: this.toggleWeaponFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.WEAPONS_DIM_KEEP_ARCHIVE, execute: false, onChange: this.toggleWeaponFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.WEAPONS_DIM_REMOVE_JUNK, execute: false, onChange: this.toggleWeaponFilters.bind(this), dimSyncRequired: true },
      ]
    },
    armor: {
      execute: true,
      groupExotics: true,
      groupByBuild: true,
      groupByArtifice: true,
      options: [
        { id: VaultCleanerFactoryId.ARMOR_BASE, execute: true, hidden: true },
        { id: undefined, execute: false },
        { id: undefined, execute: false, templateName: 'armorQualityHeader' },
        { id: VaultCleanerFactoryId.ARMOR_QUALITY, execute: true, onChange: this.toggleArmorFilters.bind(this),
          isActive: (() => this.armorTuningCheck === 'global').bind(this),
          customization: {
            formControl: this.minimumGlobalQualityFormControl,
            type: 'rating',
            override: ShapeCustomOverride,
            options: {
              ratings: ['F', 'E', 'D', 'C', 'B', 'A', 'S', 'Best']
            },
          },
        },
        { id: undefined, execute: false },
        { id: VaultCleanerFactoryId.ARMOR_TOP_SCORE, execute: true, onChange: this.toggleArmorFilters.bind(this),
          isActive: (() => this.armorTuningCheck === 'fine').bind(this),
          customization: {
            formControl: this.minimumTopScoreFormControl,
            type: 'number',
            override: {},
            options: {
              sliderMax: 34,
              sliderMin: 22
            }
          },
        },
        { id: VaultCleanerFactoryId.ARMOR_MOBRES_QUALITY, execute: true, onChange: this.toggleArmorFilters.bind(this),
          isActive: (() => this.armorTuningCheck === 'fine').bind(this),
          customization: {
            formControl: this.minimumMobResFormControl,
            type: 'number',
            override: {},
            options: {
              sliderMax: 0,
              sliderMin: -12
            }
          },
        },
        { id: VaultCleanerFactoryId.ARMOR_RECRES_QUALITY, execute: true, onChange: this.toggleArmorFilters.bind(this),
          isActive: (() => this.armorTuningCheck === 'fine').bind(this),
          customization: {
            formControl: this.minimumRecResFormControl,
            type: 'number',
            override: {},
            options: {
              sliderMax: 0,
              sliderMin: -12
            }
          },
        },
        { id: VaultCleanerFactoryId.ARMOR_BOTTOM_SCORE, execute: true, onChange: this.toggleArmorFilters.bind(this),
          isActive: (() => this.armorTuningCheck === 'fine').bind(this),
          customization: {
            formControl: this.minimumBottomScoreFormControl,
            type: 'number',
            override: {},
            options: {
              sliderMax: 34,
              sliderMin: 22
            }
          },
        },
        { id: VaultCleanerFactoryId.ARMOR_MIN_DIS, execute: true, onChange: this.toggleArmorFilters.bind(this),
          isActive: (() => this.armorTuningCheck === 'fine').bind(this),
          customization: {
            type: 'number',
            formControl: this.minimumDisFormControl,
            override: {},
            options: {
              sliderMax: 30,
              sliderMin: 2
            }
          },
        },
        { id: undefined, execute: false },
        //    { id: VaultCleanerFactoryId.ARMOR_ALL, execute: false, onChange: this.toggleArmorFilters.bind(this) },
        { id: VaultCleanerFactoryId.ARMOR_BEST_POWER_LEVEL, execute: true, onChange: this.toggleArmorFilters.bind(this) },
        { id: VaultCleanerFactoryId.ARMOR_MASTERWORK, execute: true, onChange: this.toggleArmorFilters.bind(this) },
        { id: VaultCleanerFactoryId.ARMOR_EXOTICS, execute: true, onChange: this.toggleArmorFilters.bind(this) },
        { id: undefined, execute: false },
        { id: VaultCleanerFactoryId.ARMOR_RAID, execute: false, onChange: this.toggleArmorFilters.bind(this) },
        { id: undefined, execute: false },
        { id: VaultCleanerFactoryId.ARMOR_LOCKED, execute: false },
        { id: undefined, execute: false},
        { id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_LOADOUT, execute: false, onChange: this.toggleArmorFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_FAVORITE, execute: false, onChange: this.toggleArmorFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_KEEP, execute: false, onChange: this.toggleArmorFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_INFUSE, execute: false, onChange: this.toggleArmorFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.ARMOR_DIM_KEEP_ARCHIVE, execute: false, onChange: this.toggleArmorFilters.bind(this), dimSyncRequired: true },
        { id: VaultCleanerFactoryId.ARMOR_DIM_REMOVE_JUNK, execute: false, onChange: this.toggleArmorFilters.bind(this), dimSyncRequired: true },
      ]
    }
  };

  toRemove: DestinyRecipesItem[];
  toKeep: DestinyRecipesItem[];
  processed: {
    total: number;
    count: number;
  };
  MAX_TRIES = 10;
  errorItems: {keep: DestinyRecipesItem[], remove: DestinyRecipesItem[]};
  optionStateSave = {
    armor: null,
    weapons: null,
  };
  errorStacks: any;

  taggingProcess = {
    keep: {
      loading: false,
      done: false
    },
    junk: {
      loading: false,
      done: false
    }
  };
  weaponRules: VaultCleanerConfigOption[];
  armorRules: VaultCleanerConfigOption[];

  saveState: {toKeep: DestinyRecipesItem[], toRemove: DestinyRecipesItem[]};

  executionState: {
    retryCount: number;
    state: 'locking' | 'checking' | 'done';
    counters: {
      keep: {
        processed: number;
        total: number;
      };
      remove: {
        processed: number;
        total: number;
      };
    }
  };

  processSubscription: Subscription;
  itemsInError;
  @ViewChild('sideRail', { static: true }) sideRail: AdComponent;

  @HostListener('unloaded')
  ngOnDestroy() {
    this.sideRail?.destroy();
  }

  ngOnInit(): void {
    this.authenticated = this.authService.authenticated;
    if (this.coreService.hasOption('vault.config')) {
      try {
        this.loadConfig(this.coreService.getOption('vault.config'));
      } catch (err) {
        console.error(err);
        this.coreService.deleteOption('vault.config');
      }
    }
    this.scoreDisplayType = this.coreService.loadScoreDisplayConfig();
    if (this.authenticated) {
      this.loadingVault = true;
      this.load();
    }
    this.authService.authChanged.subscribe((status) => {
      this.authenticated = status;
      if (this.authenticated) {
        this.load();
      }
    });

    this.coreService.loadingComponentState.subscribe((state) => {
      if (state.component === LoadingComponent.INVENTORY && state.loaded) {
        this.inventoryReady = true;
      }
      if (state.component === LoadingComponent.MANIFEST && state.loaded) {
        this.manifestReady = true;
      }
      if (state.component === LoadingComponent.VOLTRON && state.loaded) {
        this.voltronReady = true;
      }
      if (this.inventoryReady && this.manifestReady) {
        this.loadVault();
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (!this.useImportedData && params.mId && params.mType) {
        this.loadVaultOf(params.mType, params.mId);
      }
    });
  }

  loadConfig(savedConfig: VaultCleanerConfigImport) {
    if (!savedConfig) {
      return;
    }
    this.config.weapons.execute = savedConfig.weapons.execute;
    this.config.weapons.groupArchetypes = savedConfig.weapons.groupArchetypes;
    this.config.weapons.groupElements = savedConfig.weapons.groupElements;
    this.config.weapons.options.forEach((c) => {
      const savedRule = savedConfig.weapons.options.find((r) => r.id === c.id);
      if (savedRule) {
        c.execute = savedRule.execute;
        if (savedRule.customization?.formControl?.value !== undefined) {
          c.customization.formControl.patchValue(savedRule.customization.formControl?.value);
        }
      }
    });
    this.config.armor.execute = savedConfig.armor.execute;
    this.config.armor.groupExotics = savedConfig.armor.groupExotics;
    this.config.armor.groupByBuild = savedConfig.armor.groupByBuild;
    this.config.armor.options.forEach((c) => {
      const savedRule = savedConfig.armor.options.find((r) => r.id === c.id);
      if (savedRule) {
        c.execute = savedRule.execute;
        if (savedRule.customization?.formControl.value !== undefined) {
          c.customization.formControl.patchValue(savedRule.customization.formControl?.value);
        }
      }
    });
  }

  saveConfig() {
    // remove 'onChange' properties
    const clone = cloneDeep(this.config);
    clone.weapons.options.filter(o => o.customization).forEach((o) => { o.customization.formControl = { value: o.customization.formControl.value } as any; });
    clone.armor.options.filter(o => o.customization).forEach((o) => { o.customization.formControl = { value: o.customization.formControl.value } as any; });
    this.coreService.setOption('vault.config', clone);
  }

  loadDefaultConfig() {
    this.coreService.deleteOption('vault.config');
    this.loadConfig(defaultConfig);
  }

  updateDisplayType(type: 'percentage' | 'rating') {
    this.scoreDisplayType.type = type;
    this.coreService.setOption('global.scoreDisplay', this.scoreDisplayType);
  }

  toggleWeaponFilters(option: VaultCleanerConfigOption) {
    this.toggleOptions([
      VaultCleanerFactoryId.WEAPONS_GODROLL_PVP,
      VaultCleanerFactoryId.WEAPONS_GODROLL_PVE,
      VaultCleanerFactoryId.WEAPONS_EXOTICS,
      VaultCleanerFactoryId.WEAPONS_MASTERWORK,
      VaultCleanerFactoryId.WEAPONS_PREFER_POLY,
      VaultCleanerFactoryId.WEAPONS_CURATED,
      VaultCleanerFactoryId.WEAPONS_BEST_POWER_LEVEL,
      VaultCleanerFactoryId.WEAPONS_DIM_REMOVE_JUNK,
      VaultCleanerFactoryId.WEAPONS_DIM_KEEP_FAVORITE,
      VaultCleanerFactoryId.WEAPONS_DIM_KEEP_INFUSE,
      VaultCleanerFactoryId.WEAPONS_DIM_KEEP_KEEP,
      VaultCleanerFactoryId.WEAPONS_DIM_KEEP_ARCHIVE,
      VaultCleanerFactoryId.WEAPONS_DIM_KEEP_LOADOUT,
      VaultCleanerFactoryId.WEAPONS_CRAFTED,
      VaultCleanerFactoryId.WEAPONS_HAS_CRAFTED,
      VaultCleanerFactoryId.WEAPONS_DEEPSIGHT,
      VaultCleanerFactoryId.WEAPONS_CAN_CRAFT,
    ], option, 'weapons');

    if (option.id === VaultCleanerFactoryId.WEAPONS_PREFER_POLY && option.execute === true) {
      this.config.weapons.options.find((e) => e.id === VaultCleanerFactoryId.WEAPONS_GODROLL_PVE).execute = false;
      this.config.weapons.options.find((e) => e.id === VaultCleanerFactoryId.WEAPONS_GODROLL_PVP).execute = false;
    } else if (option.execute === true && (option.id === VaultCleanerFactoryId.WEAPONS_GODROLL_PVE || option.id === VaultCleanerFactoryId.WEAPONS_GODROLL_PVP)) {
      this.config.weapons.options.find((e) => e.id === VaultCleanerFactoryId.WEAPONS_PREFER_POLY).execute = false;
    }
  }

  toggleArmorFilters(option: VaultCleanerConfigOption) {
    this.toggleOptions([
      VaultCleanerFactoryId.ARMOR_BEST_POWER_LEVEL,
      VaultCleanerFactoryId.ARMOR_MASTERWORK,
      VaultCleanerFactoryId.ARMOR_EXOTICS,
      VaultCleanerFactoryId.ARMOR_CURRENT_SEASON,
      VaultCleanerFactoryId.ARMOR_RAID,
      VaultCleanerFactoryId.ARMOR_DIM_REMOVE_JUNK,
      VaultCleanerFactoryId.ARMOR_DIM_KEEP_FAVORITE,
      VaultCleanerFactoryId.ARMOR_DIM_KEEP_INFUSE,
      VaultCleanerFactoryId.ARMOR_DIM_KEEP_KEEP,
      VaultCleanerFactoryId.ARMOR_DIM_KEEP_ARCHIVE,
      VaultCleanerFactoryId.ARMOR_DIM_KEEP_LOADOUT,
    ], option, 'armor');
  }

  toggleOptions(optionsToToggle: VaultCleanerFactoryId[], changed: VaultCleanerConfigOption, type: 'armor' | 'weapons') {
    if (changed.id === VaultCleanerFactoryId.ARMOR_ALL || changed.id === VaultCleanerFactoryId.WEAPONS_ALL) {
      if (changed.execute === true) {
        this.optionStateSave[type] = [];
        this.config[type].options
          .filter((o) => optionsToToggle.indexOf(o.id) > -1)
          .forEach((opt) => {
            this.optionStateSave[type].push(opt.execute);
            opt.execute = false;
          });
      } else if (this.optionStateSave[type] != null) {
        this.config[type].options
          .filter((o) => optionsToToggle.indexOf(o.id) > -1)
          .forEach((opt, idx) => opt.execute = this.optionStateSave[type][idx]);
        this.optionStateSave[type] = [];
      }
    } else if (optionsToToggle.indexOf(changed.id) > -1 && changed.execute === true) {
      const conf = this.config[type].options.find((opt) => opt.id === VaultCleanerFactoryId.ARMOR_ALL || opt.id === VaultCleanerFactoryId.WEAPONS_ALL);
      if (conf) {
        conf.execute = false;
      }
      this.optionStateSave[type] = [];
    }
  }

  load() {
    if (this.loaded) { return; }
    this.loaded = true;
    this.loadingVault = true;
    const missingComponents = [];
    if (!this.data.manifest) {
      missingComponents.push(LoadingComponent.MANIFEST);
    }
    if (!this.data.inventory) {
      missingComponents.push(LoadingComponent.INVENTORY);
    }
    if (!this.voltronService.voltronLoaded()) {
      missingComponents.push(LoadingComponent.VOLTRON);
    }
    if (missingComponents.length > 0) {
      this.coreService.loadComponents(missingComponents);
    } else {
      this.manifestReady = true;
      this.inventoryReady = !!this.data.inventory;
      this.loadVault();
    }
  }

  loadAnon(data: DestinyProfileResponse) {
    if (this.loaded && this.useImportedData && this.importedDataService.ownerId === data.profile.data.userInfo.membershipId) { return; }
    this.loaded = true;
    this.useImportedData = true;
    this.loadingVault = true;

    if (!this.manifestReady) {
      this.coreService.waitFor(LoadingComponent.MANIFEST)
        .subscribe({
          next: () => {
            this.importedDataService.importInventory(data);
            this.useImportedData = true;
            this.manifestReady = true;
            this.inventoryReady = true;
            this.loadingVault = true;
            this.toastr.success(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.LOADED_VAULT_SUCCESS', {name: this.importedDataService.owner}));
            this.router.navigate(['.'], { queryParams: {mId: this.importedDataService.currentMemberShip.membershipId, mType: this.importedDataService.currentMemberShip.membershipType}, relativeTo: this.route});
            window.scroll(0, 0);
            this.loadVault();
          },
          error: () => {
            this.manifestReady = false;
            this.inventoryReady = false;
            this.loadingVault = false;
          }
        });
      this.coreService.loadComponents([LoadingComponent.VOLTRON, LoadingComponent.MANIFEST], { background: true });
    } else {
      this.importedDataService.importInventory(data);
      this.useImportedData = true;
      this.manifestReady = true;
      this.inventoryReady = true;
      this.loadingVault = true;
      this.toastr.success(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.LOADED_VAULT_SUCCESS', {name: this.importedDataService.owner}));
      this.router.navigate(['.'], { queryParams: {mId: this.importedDataService.currentMemberShip.membershipId, mType: this.importedDataService.currentMemberShip.membershipType}, relativeTo: this.route});
      window.scroll(0, 0);
      this.loadVault();
    }
  }

  loadVaultOf(membershipType: number, membershipId: string) {
    console.log(`Loading vault of ${membershipType} ${membershipId}`);
    this.importedDataService.loadGuardianInventory({membershipId, membershipType} as UserInfoCard)
      .subscribe({ next: (profileResponse) => {
          this.loadAnon(profileResponse);
        }, error: (err) => {
          logError(err);
          this.toastr.error(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.COULD_NOT_IMPORT'));
          this.router.navigate(['.'], {relativeTo: this.route});
        }});
  }

  loadVault() {
    if (this.loadingVault === false) { return; }
    this.weapons = this.data.filterInventory({category: [DestinyItemCategory.WEAPON]}).length;
    this.armors = this.data.filterInventory({category: [DestinyItemCategory.ARMOR]}).length;
    this.totalItems = this.data.filterInventory({fields: {location: ItemLocation.Vault, bucketHash: 138197802 }}).length;
    this.catagoryNames = {};
    this.data.getDefinitions<DestinyItemCategoryDefinition>('DestinyItemCategoryDefinition').values.forEach((value) => {
      this.catagoryNames[value.hash] = value.displayProperties.name;
    });
    // TODO force glaive definition
    const glaive = this.data.getDefinitionById<DestinyPresentationNodeDefinition>('DestinyPresentationNodeDefinition', 504540513);
    this.catagoryNames[DestinyItemCategory.W_GLAIVE] = glaive.displayProperties.name;
    this.loadingVault = false;
  }

  refresh() {
    if (this.manifestReady && this.inventoryReady && !this.reloading) {
      this.reloading = true;
      this.coreService.loadComponents([LoadingComponent.INVENTORY], { background: true });
      this.dimSyncService.refresh();
      this.coreService.waitFor(LoadingComponent.INVENTORY)
        .subscribe({ next: () => { this.reloading = false; }, error: () => {this.reloading = false; } });
    }
  }

  shareVault() {
    this.modalService.create({
      component: ShareModalComponent,
      confirmButtonText: 'COMMON.CLOSE',
      title: 'SECTIONS.VAULT_CLEANER.vault-cleaner.SHARE_YOUR_VAULT',
      data: {
        vaultSpace: this.totalItems
      }
    });
  }

  importVault() {
    this.modalService.create({
      component: ImportModalComponent,
      confirmButtonText: 'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.IMPORT_VAULT',
      title: 'SECTIONS.VAULT_CLEANER.vault-cleaner.IMPORT_VAULT',
      cancelButton: true,
      cancelButtonText: 'COMMON.CANCEL',
      data: {}
    }).subscribe((response) => {
      if (response && response.canceled === false && response.data !== undefined) {
        this.loadAnon(response.data);
      }
    });
  }

  useOwnVault() {
    this.useImportedData = false;
    this.importedDataService.purge();
    this.router.navigate(['.'], {relativeTo: this.route});
    this.refresh();
  }

  startSorting(syncWithImport: boolean = false) {
    this.processing = true;
    this.currentStepIndex = 0;
    this.taggingProcess = {
      keep: {
        loading: false,
        done: false
      },
      junk: {
        loading: false,
        done: false
      }
    };
    this.dimSyncService.refresh()
      .pipe(catchError(() => {
        // If DIM Sync is not available, should not block processing
        this.toastr.warning(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.UNAVAILABLE'));
        return of(undefined);
      }))
      .subscribe(() => {
        this.saveConfig();
        this.steps = [];
        if (this.config.weapons.execute) {
          this.steps.push(..._.cloneDeep(this.defaultSteps.filter((step) => step.category === 'weapon' && step.active)));
        }
        if (this.config.armor.execute) {
          this.steps.push(..._.cloneDeep(this.defaultSteps.filter((step) => step.category === 'armor' && step.active)));
        }
        this.weaponRules = convertArrayToObject(this.config.weapons.options.filter((o) => o.execute), 'id');
        this.armorRules = convertArrayToObject(this.config.armor.options.filter((o) => o.execute), 'id');

        this.steps.forEach((s) => s.items = undefined);
        // Process all steps
        while (this.currentStepIndex !== this.steps.length) {
          this.processCurrentStep(syncWithImport);
          this.currentStepIndex++;
        }
        this.currentStepIndex = 0;
        this.processing = false;
        this.state = 'sorting';
        this.polyvalentScoresOnly = this.config.weapons.options.find((e) => e.id === VaultCleanerFactoryId.WEAPONS_PREFER_POLY).execute;
        if (this.polyvalentScoresOnly) {
          this.updateShowPoly(true);
        }
        this.coreService.registerAnalyticsEvent('vault_process_start', 'Vault Cleaner Events', this.authService.membershipData?.bungieNetUser?.membershipId || 'anonymous');
        this.processCurrentStep();
      });
  }

  updateShowPoly(value: boolean) {
    this.scoreDisplayType.showPoly = value;
    this.coreService.setOption('global.scoreDisplay', this.scoreDisplayType);
  }

  processStep(step: CleaningStep, syncWithImport: boolean = false) {
    if (!step.items) {
      step.items = {};
      const filterConfig = step.category === 'weapon' ? this.config.weapons.options : this.config.armor.options;
      const rules: VaultCleanerFactoryId[] = filterConfig.filter((o) => o.execute).map((o) => o.id);
      let items;
      if (step.category === 'weapon') {
        const weapons = this.ruleService.checkRules(this.config, rules, this.voltronService.calculateScore(this.data.filterInventory({category: step.type, notType: step.notType})));
        if (this.config.weapons.groupElements && !this.config.weapons.groupArchetypes) {
          items = _.groupBy(weapons, 'definition.defaultDamageTypeHash');
        } else if (this.config.weapons.groupArchetypes) {
          items = this.groupByArchetype(weapons, this.config.weapons.groupElements);
        } else {
          items = _.groupBy(weapons, 'itemHash');
        }
      } else {
        const armors = this.ruleService.checkRules(this.config, rules, this.data.filterInventory({category: step.type, notType: step.notType}));
        items = _.groupBy(armors, 'armorPieceType');
        if (this.config.armor.groupByBuild) {
          items = this.groupByBuild(items);
        }
        if (this.config.armor.groupByArtifice) {
          items = this.groupByArtifice(items);
        }
        if (this.config.armor.groupExotics) {
          // Needs to be places after groupByBuild : only extracts exotics from existing groups
          items = this.groupExotics(items);
        }
      }
      if (syncWithImport) {
        Object
          .keys(items)
          .filter((itemHash) => items[itemHash].length > 0)
          .forEach((itemHash) => {
            step.items[itemHash] = {
              keep: this.toKeep.filter((i) => this.syncStepItemWithImport(step, itemHash, i)),
              remove: this.toRemove.filter((i) => this.syncStepItemWithImport(step, itemHash, i)),
            };
          });
      } else {
        Object
          .keys(items)
          .filter((itemHash) => items[itemHash].length > 0)
          .forEach((itemHash) => {
            step.items[itemHash] = this.filterItems(items[itemHash], filterConfig);
          });
      }
    }
  }

  private groupExotics(items: {[key: string]: DestinyRecipesItem[]}) {
    const additionalGroups = {};
    Object.keys(items).forEach((armorGroup) => {
      items[armorGroup] = items[armorGroup].filter((item: DestinyRecipesItem, idx) => {
        if (item.definition.inventory.tierType === TierType.Exotic) {
          const groupName = item.itemHash + ((this.config.armor.groupByBuild && item.armorPieceType !== DestinyItemSubType.ClassArmor) ? '.' + item.armorAppraisal.decayDetails.bestBuild : '');
          if (!additionalGroups[groupName]) {
            additionalGroups[groupName] = [];
          }
          additionalGroups[groupName].push(items[armorGroup][idx]);
          return false;
        }
        return true;
      });
    });
    return {...items, ...additionalGroups};
  }

  private groupByBuild(items: {[key: string]: DestinyRecipesItem[]}) {
    const groups = {};
    const armorItems = [];
    Object.keys(items).forEach((armorGroup) => {
      armorItems.push(...items[armorGroup]);
    });
    armorItems.forEach((armor: DestinyRecipesItem) => {
      let groupName;
      if (!armor.armorAppraisal) {
        groupName = '0';
      } else if (armor.armorAppraisal.isClassItem) {
        groupName = `${DestinyItemSubType.ClassArmor}`;
      } else {
        groupName = `${armor.armorPieceType}.${armor.armorAppraisal.decayDetails.bestBuild}`;
      }
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(armor);
    });
    Object.keys(groups).forEach((groupName) => {
      groups[groupName].sort((a, b) => a.armorAppraisal?.rating < b.armorAppraisal?.rating);
    });
    return groups;
  }

  private groupByArtifice(groups: {[key: string]: DestinyRecipesItem[]}) {
    const newGroups = {};
    Object.entries(groups).forEach(([groupName, items]) => {
      const artifice = items.filter((i) => ItemHelper.isArtificeArmor(i.definition));
      const notArtifice = items.filter((i) => !ItemHelper.isArtificeArmor(i.definition));
      if (notArtifice.length > 0) {
        newGroups[groupName] = notArtifice;
      }
      if (artifice.length > 0) {
        newGroups[groupName + '.artifice'] = artifice;
      }
    });
    return newGroups;
  }

  private groupByArchetype(weapons: DestinyRecipesItem[], withElements: boolean) {
    const groups: any = {};
    if (weapons) {
      weapons.forEach((weapon) => {
        const intrinsicCategoriesIdx = weapon.definition.sockets?.socketCategories?.find((s) => s.socketCategoryHash === 3956125808)?.socketIndexes;
        if (intrinsicCategoriesIdx) {
          for (const idx of intrinsicCategoriesIdx) {
            const groupName = weapon.definition.sockets.socketEntries[idx].singleInitialItemHash + (withElements ? `.${weapon.definition.defaultDamageTypeHash}` : '');
            if (!groups[groupName]) {
              groups[groupName] = [];
            }
            groups[groupName].push(weapon);
          }
        } else {
          if (!groups[773524094]) {
            groups[773524094] = [];
          }
          groups[773524094].push(weapon);
        }
      });
      Object.keys(groups).forEach((g) => {
        groups[g].sort((a: DestinyRecipesItem, b: DestinyRecipesItem) => a.itemHash - b.itemHash);
      });
    }
    return groups;
  }

  private syncStepItemWithImport(step: CleaningStep, key: string, item: DestinyRecipesItem) {
    const hash = parseInt(key, 10);
    if (step.category === 'weapon') {
      return item.itemHash === hash;
    } else {
      return item.armorPieceType === hash && item.definition.itemCategoryHashes.indexOf(step.type[0]) > -1;
    }
  }

  processCurrentStep(syncWithImport: boolean = false) {
    if (this.state === 'sorting' && !syncWithImport) {
      window.scroll(0, 0);
    }
    this.coreService.startWatchingErrors('[Vault] Processing step');
    this.processStep(this.currentStep, syncWithImport);
    this.coreService.stopWatchingErrors();
  }

  processReviewStep() {
    window.scroll(0, 0);
    this.coreService.startWatchingErrors('[Vault] Final Review');
    this.currentStepIndex = this.steps.length - 1;
    this.toRemove = this.steps.reduce((out, step) => {
      out.push(...Object.keys(step.items).reduce((acc, weaponType) => {
        acc.push(...step.items[weaponType].remove);
        return acc;
      }, []));
      return out;
    }, []);
    this.toKeep = this.steps.reduce((out, step) => {
      out.push(...Object.keys(step.items).reduce((acc, weaponType) => {
        acc.push(...step.items[weaponType].keep);
        return acc;
      }, []));
      return out;
    }, []);
    this.state = 'executing';
    this.coreService.registerAnalyticsEvent('vault_review', 'Vault Cleaner Events', this.authService.membershipData?.bungieNetUser?.membershipId || 'anonymous');
    this.coreService.stopWatchingErrors();
  }

  rearrangeRow(rule: 'keep-all' | 'pvp' | 'pve' | 'poly' | 'remove-all', items: {keep?: DestinyRecipesItem[], remove?: DestinyRecipesItem[]}) {
    if (!items.keep) {
      items.keep = [];
    }
    if (!items.remove) {
      items.remove = [];
    }
    if (rule !== 'keep-all' && rule !== 'remove-all' && items.remove.every((i) => !i.score) && items.keep.every((i) => !i.score)) {
      this.toastr.info(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.NO_PERK_RATINGS'));
    }
    switch (rule) {
      case 'keep-all':
        items.keep.push(...items.remove.splice(0));
        break;
      case 'pvp': {
        const maxPvP = _.maxBy([...items.remove, ...items.keep], (i) => i.score?.pvp)?.score?.pvp || 0;
        items.keep.push(..._.remove(items.remove, (i: DestinyRecipesItem) => i.score?.pvp >= maxPvP));
        break;
      }
      case 'pve': {
        const maxPvE = _.maxBy([...items.remove, ...items.keep], (i) => i.score?.pve)?.score?.pve || 0;
        items.keep.push(..._.remove(items.remove, (i: DestinyRecipesItem) => i.score?.pve >= maxPvE));
        break;
      }
      case 'poly': {
        const maxPoly = _.maxBy([...items.remove, ...items.keep], (i) => i.score?.polyvalent)?.score?.polyvalent || 0;
        items.keep.push(..._.remove(items.remove, (i: DestinyRecipesItem) => i.score?.polyvalent >= maxPoly));
        break;
      }
      case 'remove-all':
      default:
        items.remove.push(...items.keep.splice(0));
        break;
    }
  }

  prevStep() {
    if (this.state === 'sorting') {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex--;
        this.processCurrentStep();
      } else {
        // TODO warning and remove current progress
        this.state = 'config';
      }
    }
  }

  nextStep() {
    if (this.state === 'sorting') {
      if (this.currentStepIndex < this.steps.length - 1) {
        this.currentStepIndex++;
        this.processCurrentStep();
      } else {
        this.processReviewStep();
      }
    }
  }

  goToStep(idx) {
    if (idx === 'end') {
      this.processReviewStep();
    } else if (idx === 'config') {
      // TODO display warning and erase progress
      this.state = 'config';
    } else if (this.steps[idx]) {
      this.state = 'sorting';
      this.currentStepIndex = idx;
      this.processCurrentStep();
    }
  }

  filterItems(items: DestinyRecipesItem[], config: VaultCleanerConfigOption[]): { keep?: DestinyRecipesItem[], remove?: DestinyRecipesItem[] } {
    const out = {keep: [], remove: []};
    const rules = config.filter((o) => o.execute).map((o) => o.id);
    items.forEach((item) => {
      if (this.shouldKeep(item, rules, items)) {
        out.keep.push(item);
      } else {
        out.remove.push(item);
      }
    });
    return out;
  }

  shouldKeep(item: DestinyRecipesItem, rules: VaultCleanerFactoryId[], allItems: DestinyRecipesItem[]): boolean {
    let i = 0;
    let currentStatus;
    const superRules = _.intersection(this.superRules, rules);
    if (superRules?.length) {
      // Check SUPER rules first. If one of these is TRUE, the item should be kept no matter what
      let j = 0;
      while (j < superRules.length) {
        try {
          const superRuleResult = this.ruleService.execRule(superRules[j], item, allItems, this.config);
          const vaultCleanerResultExistingIdx = item.vaultCleanerResult.findIndex((r) => r.rule === superRules[j]);
          if (vaultCleanerResultExistingIdx === -1) {
            item.vaultCleanerResult.push({rule: superRules[j], result: superRuleResult});
          } else {
            item.vaultCleanerResult[vaultCleanerResultExistingIdx].result = superRuleResult;
          }
          if (superRuleResult?.result === true) {
            return true;
          }
        } catch (err) { /* Rule not active hack */ }
        j++;
      }
    }
    while (i < rules.length) {
      try {
        const ruleResult = this.ruleService.execRule(rules[i], item, allItems, this.config);
        const vaultCleanerResultExistingIdx = item.vaultCleanerResult.findIndex((r) => r.rule === rules[i]);
        if (vaultCleanerResultExistingIdx === -1) {
          item.vaultCleanerResult.push({rule: rules[i], result: ruleResult});
        } else {
          item.vaultCleanerResult[vaultCleanerResultExistingIdx].result = ruleResult;
        }
        // One false is enough to discard an item. Rule check can be short-circuited
        if (ruleResult?.result === false) {
          return false;
        }
        // One true is not enough to keep an object. All rules must be checked
        if (ruleResult?.result === true) {
          currentStatus = true;
        }
      } catch (err) {
        if (!err.message?.startsWith('Rule not active')) {
          console.error('Error while executing rule', rules[i], err);
        }
        /* Rule not active hack */
      }
      i++;
    }
    // If all rules returned undefined, the object can be discarded
    return currentStatus;
  }

  /* Drag & Drop logic **/

  onDragStart(item: DestinyRecipesItem, fromState: 'keep' | 'remove', zoneName: string) {
    this.coreService.closeTooltip();
    this.currentDropZone = zoneName;
    this.fromState = fromState;
  }

  onDragEnd() {
    this.currentDropZone = undefined;
    this.fromState = undefined;
  }

  onDrop(ev: DndDropEvent, action: 'keep' | 'remove', zoneName: string) {
    if (this.fromState !== action) {
      const category = zoneName;
      const idxFrom = this.currentStep.items[category][this.fromState].findIndex((item) => item.itemInstanceId === ev.data.itemInstanceId);
      this.currentStep.items[category][action].push(ev.data);
      this.currentStep.items[category][this.fromState].splice(idxFrom, 1);
      this.currentDropZone = undefined;
      this.fromState = undefined;
    }
  }

  finalProcess() {
    this.error = undefined;
    this.state = 'locking';
    this.saveState = JSON.parse(JSON.stringify({toKeep: this.toKeep, toRemove: this.toRemove}));
    this.processed = { total: this.toKeep.length + this.toRemove.length, count: 0 };
    try {
      this.errorStacks = {};
      this.execLocks(this.toKeep, this.toRemove, 1);
    } catch (err) {
      logError(err);
    }
  }

  copyDimQuery() {
    this.clipboard.copy(this.toRemove.map((i) => 'id:' + i.itemInstanceId).join(' or '));
    this.toastr.success(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.COPIED_DIM_QUERY'));
  }

  importCleanup() {
    this.modalService.create({
      component: ImportCleanupModalComponent,
      confirmButtonText: 'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.IMPORT_CLEANUP',
      cancelButton: true,
      title: 'SECTIONS.VAULT_CLEANER.vault-cleaner.IMPORT_CLEANUP',
      data: {}
    }).subscribe((result) => {
      if (result && result.canceled === false) {
        this.backgroundSorting = true;
        this.toastr.success(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.CLEANUP_IMPORTED'));
        this.toKeep = result.data.toKeep;
        this.toRemove = result.data.toRemove;
        this.steps = [];
        this.startSorting(true);
        this.state = 'executing';
        this.backgroundSorting = false;
        window.scroll(0, 0);
      }
    });
  }

  exportCleanup() {
    const toExport: CleanupConfig = {
      ownerId: this.data.ownerId,
      owner: this.data.owner,
      ownerCode: this.data.ownerCode,
      toKeep: this.toKeep,
      toRemove: this.toRemove,
      date: Date.now(),
      cleaner: this.dataService.owner,
      cleanerCode: this.dataService.ownerCode
    };
    const file = new Blob([JSON.stringify(toExport)], { type: 'application/json'});
    saveAs(file, `vault_clean_${this.data.owner}_${new Date(toExport.date).toISOString()}.json`);
    this.toastr.info(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.DOWNLOAD_SHOULD_START'));
  }

  closeVault() {
    this.toastr.info(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.VAULT_OF_CLOSED', {name: this.importedDataService.owner}));
    this.useOwnVault();
    this.router.navigate(['.'], {relativeTo: this.route});
    this.state = 'config';
    window.scroll(0, 0);
  }

  private execLocks(keep: DestinyRecipesItem[], remove: DestinyRecipesItem[], tryNumber: number) {
    this.executionState = {
      retryCount: this.executionState ? this.executionState.retryCount + 1 : 0,
      state: 'locking',
      counters: {
        keep: {
          processed: 0,
          total: keep.length
        },
        remove: {
          processed: 0,
          total: remove.length
        },
      }
    };
    this.coreService.startWatchingErrors('[Vault] Process');
    if (tryNumber > this.MAX_TRIES) {
      this.error = 'SECTIONS.VAULT_CLEANER.vault-cleaner.COULD_NOT_PROCESS';
      this.errorItems = {keep, remove};
      this.state = 'done';
      throw new MaxTriesError(this.errorItems, this.errorStacks);
    }
    this.itemsInError = [];
    this.processSubscription = this.changeItemLock(remove, false, tryNumber)
      .pipe(
        debounceTime(tryNumber > 5 ? 5000 : 1000),
        mergeMap(() => this.changeItemLock(keep, true, tryNumber)),
        mergeMap(() => this.checkState())
      )
      .subscribe({ next: (state: {shouldHaveBeenKept, shouldHaveBeenRemoved}) => {
          if (state !== null) {
            this.execLocks(state.shouldHaveBeenKept, state.shouldHaveBeenRemoved, tryNumber + 1);
          } else {
            this.state = 'done';
            this.coreService.stopWatchingErrors();
          }
        }, error: (err) => {
          this.coreService.stopWatchingErrors();
          throw err;
        }});
  }

  private checkState() {
    return new Observable((obs) => {
      this.executionState.state = 'checking';
      if (this.itemsInError.length === 0) {
        this.executionState.state = 'done';
        obs.next(null);
      }
      const shouldHaveBeenRemoved = this.toRemove
        .filter((item) => Boolean(this.itemsInError.find((e) => e.itemInstanceId === item.itemInstanceId)))
        .filter((item) => ItemHelper.isLocked(this.data.inventory.find((e) => e.itemInstanceId === item.itemInstanceId)))
        .map((i) => this.data.inventory.find((e) => e.itemInstanceId === i.itemInstanceId));
      const shouldHaveBeenKept = this.toKeep
        .filter((item) => Boolean(this.itemsInError.find((e) => e.itemInstanceId === item.itemInstanceId)))
        .filter((item) => !ItemHelper.isLocked(this.data.inventory.find((e) => e.itemInstanceId === item.itemInstanceId)))
        .map((i) => this.data.inventory.find((e) => e.itemInstanceId === i.itemInstanceId));
      if (shouldHaveBeenKept.length || shouldHaveBeenRemoved.length) {
        obs.next({shouldHaveBeenKept, shouldHaveBeenRemoved});
      } else {
        this.executionState.state = 'done';
        obs.next(null);
      }
      obs.complete();
    });
  }

  private changeItemLock(itemsToLock: DestinyRecipesItem[], state: boolean, attemptNumber = 1) {
    return from(itemsToLock).pipe(
      delay(25 * attemptNumber),
      mergeMap((item) => {
        if (ItemHelper.isLocked(item) !== state) {
          return this.data.setItemLockState(item, state)
            .pipe(
              tap((result: BungieApiResponse<any>) => {
                if (!result || result.ErrorStatus !== 'Success' || result.Message !== 'Ok') {
                  this.itemsInError.push(item);
                }
              }),
              catchError((err) => {
                logError('Could not change item state', item, err);
                this.itemsInError.push(item);
                this.errorStacks[item.itemInstanceId] = JSON.stringify(serializeError(err));
                return of(false);
              }),
            );
        }
        return of(item);
      }, Math.max(Math.floor(5 / attemptNumber), 1)),
      tap(() => this.executionState.counters[state ? 'keep' : 'remove'].processed++),
      toArray(),
    );
  }

  openScoreModale() {
    this.modalService.create({
      title: 'SECTIONS.VAULT_CLEANER.vault-cleaner.SCORE_EXPLANATION',
      confirmButtonText: 'COMMON.CLOSE',
      data: this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.SCORE_EXPLANATION_CONTENT'),
      component: TextModalComponent
    });
  }

  tagItems(tag: 'keep' | 'junk') {
    if (this.taggingProcess[tag].loading) { return; }
    const payloads: TagUpdate[] = [];
    const items = tag === 'keep' ? this.toKeep : this.toRemove;
    let confirm$: any = of({data: true});
    if (items.some((i) => this.dimSyncService.getAnnotationsForItem(i.itemInstanceId)?.tag)) {
      confirm$ = this.modalService.create({
        component: ConfirmTagModalComponent,
        title: 'SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.OVERRIDE_TAGS',
        data: { tag },
        confirmButtonText: 'COMMON.CANCEL'
      });
    }
    confirm$.subscribe((response) => {
      if (response?.data === undefined) { return; }
      let filteredItems = items;
      if (response.data === false) {
        filteredItems = items.filter((i) => !this.dimSyncService.getAnnotationsForItem(i.itemInstanceId)?.tag);
      }
      for (const item of filteredItems) {
        payloads.push({
          action: 'tag',
          payload: {
            tag,
            id: item.itemInstanceId
          }
        });
      }
      if (payloads?.length) {
        this.taggingProcess[tag].loading = true;
        this.dimSyncService.sendTagUpdates(payloads).subscribe(() => {
          const tagTranslated = this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.TAGS.' + tag);
          this.toastr.success(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.ITEMS_TAGGED', { amount: filteredItems.length, tag: tagTranslated }));
          this.taggingProcess[tag].loading = false;
          this.taggingProcess[tag].done = true;
        }, (err) => {
          this.toastr.error(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.TAG_ERROR'));
          this.taggingProcess[tag].loading = false;
        });
      }
    });
  }

  openArmorGuide() {
    this.modalService.create({
      title: 'SECTIONS.VAULT_CLEANER.vault-cleaner.ARMOR_GUIDE',
      confirmButtonText: 'COMMON.CLOSE',
      data: this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.ARMOR_GUIDE_CONTENT'),
      component: ArmorGuideComponentDialog
    });
  }

  cancelProcess() {
    if (this.processSubscription) {
      this.processSubscription.unsubscribe();
    }
    this.toastr.info(this.translate.instant('SECTIONS.VAULT_CLEANER.vault-cleaner.CANCELLED'));
    this.goToStep('end');
  }

}
