import { AppEnv } from './env.type';

export const environment: AppEnv = {
  production: true,
  version: '1.11.12',
  apiConfig: {
    apiKey: 'e94d158e098944b1a54cda6b9944fb6f',
    client_id: '33647',
    client_secret: 'mOcW2pvmC.yBcPjwnTgaL-j-xFrbikWmlDqPbtU9WeY'
  },
  recipesApi: 'https://api.destinyrecipes.com',
  dataUrl: 'http://localhost:4242',
  frontendUrl: 'https://destinyrecipes.com',
  dbConfig: {
    name: 'destiny-recipes',
    version: 3,
    objectStoresMeta: [
      {
        store: 'manifest',
        storeConfig: { keyPath: 'id', autoIncrement: false},
        storeSchema: [
          { name: 'version', keypath: 'version', options: { unique: false } },
          { name: 'lang', keypath: 'lang', options: { unique: false } },
          { name: 'value', keypath: 'value', options: { unique: false } }
        ]
      },
      {
        store: 'inventory',
        storeConfig: { keyPath: 'id', autoIncrement: false},
        storeSchema: [
          { name: 'value', keypath: 'value', options: { unique: false } }
        ]
      },
    ],
  },
  dimSyncApiKey: '4a51d0fe-e33a-42f7-9f5e-3cc359c7bd5b',
  dimSyncHost: 'https://api.destinyitemmanager.com'
};
